import type { ComponentType } from "react"
import { useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"

export const withDelayedVisibility = (Component): ComponentType => {
    return (props) => {
        const { style, ...rest } = props

        useEffect(() => {
            setTimeout(() => {
                style.visibility = "visible"
                style.height = "100vh"
            }, 6000)
        }, [])

        return (
            <AnimatePresence>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <Component
                        {...rest}
                        style={{ ...style, position: "fixed" }}
                    />
                </motion.div>
            </AnimatePresence>
        )
    }
}
